<template>
  <div :class="['favorite-card', selectedProduct.includes(currentProduct.id) ? 'selected' : '']" >
    <div class="product-card" :class="[addClassProduct]">
      <div class="product-card__head">
        <div class="product-features">
          <template v-for="(item, index) in getSticker()" :key="index">
            <div :class="item.class" class="product-feature">{{ item.title }}</div>
          </template>
        </div>
        <router-link
          :to="{
            name: 'product',
            params: { detail: currentProduct.detail, subsection: currentProduct.section },
          }">
          <div class="product-card__img">
            <LazyPicture
              src-placeholder="/img/no_img_src.png"
              :src="currentProduct.picture"
              :alt="currentProduct.name"
              class="blur-up"
            />
          </div>
        </router-link>

        <div class="product-card__like">
          <LikeButton
            :productId="mainProductId"
            :isFavourite="currentProduct.isFavoriteUser"
          />
        </div>
      </div>
      <div class="product-card__body">
        <router-link
          :to="{
            name: 'product',
            params: { detail: currentProduct.detail, subsection: currentProduct.section },
          }"
          class="product-card__title">
          {{ currentProduct.name }}
        </router-link>
      </div>
      <div class="product-card__footer">
        <div v-if="currentProduct.fit_until" class="product-card__fit-until">Годен до: {{ currentProduct.fit_until }}</div>
        <div class="product-offers">
          <button
            v-for="(offer, index) in currentProduct.offers"
            :key="offer.id"
            @click.prevent="changeOffer(index)"
            :class="['product-offers__button product-offers__button--small', index === this.currentOffer ? 'active' : '']"
          >
            {{ offer.color }}
          </button>
        </div>
        <div class="product-card__info">
          <div class="product-card__info-price">{{ getPrice() }}</div>
          <div class="product-card__info-oldprice" v-if="currentProduct.isDiscount">
            {{ currentProduct.price + " " + currentProduct.currency }}
          </div>
        </div>
        <div class="product-card__buy">
          <button @click.prevent="addProductToCart()"
                  :disabled="currentProduct.quantity - productCountInBasket <= 0"
                  class="btn">
            <svg class="icon icon-bag">
              <use xlink:href="#bag"></use>
            </svg>
          </button>
          <div v-if="productCountInBasket" class="product-card--in-basket">{{ productCountInBasket }}</div>
          <button
            class="btn--favorite"
            @click="selectProduct()"
            :disabled="currentProduct.quantity - productCountInBasket <= 0">
            Выбрать
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LikeButton from "@/components/button/like";

export default {
  components: {
    LikeButton,
  },

  props: {
    product: {
      type: Object,
      required: true,
    },
    classItem: {
      type: String,
      required: false,
      default: '',
    },
    selectedProduct: {
      type: Object,
      required: false,
    }
  },

  data() {
    return {
      currentProduct: {},
      currentOffer: 0,
      mainProductId: null,
    };
  },

  created() {
    this.currentProduct = this.product;
    this.mainProductId = this.product.id;

    if (this.product.offers.length > 0) {
      let offer = this.product.offers[0];

      this.currentProduct = {...this.product, ...offer};
    }
  },

  computed: {
    addClassProduct() {
      if (!this.currentProduct.isDiscount)
        return this.classItem;
      else
        return "product-card--sale" + " " + this.classItem;
    },

    productCountInBasket() {
      if (Object.keys(this.$store.state.basket).length > 0)
        return this.getCountInBasketUser();

      if (this.$store.state.basketGuest)
        return this.getCountInBasketGuestUser();

      return 0;
    },
  },

  methods: {
    selectProduct() {
      this.$emit('selectedProduct', this.currentProduct.id);
    },

    addProductToCart() {
      const data = {PRODUCT_ID: this.currentProduct.id, QUANTITY: 1};
      this.$store
        .dispatch("CHECKOUT_SEND_PRODUCT_TO_CART", data)
        .catch((error) => {
          if (error.response.data.errors)
            this.errors = error.response.data.errors;

          this.$store.commit("SET_MESSAGE_MODAL_DATA", error.response.data);
        });
    },

    changeOffer(key) {
      let offer = this.currentProduct.offers[key];

      this.currentProduct = {...this.currentProduct, ...offer};
      this.currentOffer = key;
    },

    getPrice() {
      if (this.currentProduct.isDiscount)
        return this.currentProduct.priceDiscount + " " + this.currentProduct.currency;
      else
        return this.currentProduct.price + " " + this.currentProduct.currency;
    },

    getSticker() {
      let sticker = [];

      if (this.currentProduct.stocks)
        sticker.push({
          'title': 'Акция',
          'class': 'product-feature--orange'
        });

      if (this.currentProduct.novelties)
        sticker.push({
          'title': 'Новинка',
          'class': 'product-feature--green'
        });

      if (this.currentProduct.sovetuem)
        sticker.push({
          'title': 'Советуем',
          'class': 'product-feature--purple'
        });

      if (this.currentProduct.hit)
        sticker.push({
          'title': 'Хит',
          'class': 'product-feature--blue'
        });

      if (this.currentProduct.preimushestva)
        sticker.push({
          'title': 'Преимущества',
          'class': 'product-feature--yellow'
        });

      if (this.currentProduct.is_partner_product)
        sticker.push({
          'title': 'Товар партнера',
          'class': 'product-feature--pink'
        });

      if (this.currentProduct.is_social_product)
        sticker.push({
          'title': 'Социальный товар',
          'class': 'product-feature--red'
        });

      return sticker;
    },

    /**
     * Количество товара в корзине авторизованного пользователя
     */
    getCountInBasketUser() {
      let products = this.$store.state.basket.items;
      let product = Object.values(products).find((item) => parseInt(item.id) === this.currentProduct.id);

      if (product)
        return parseInt(product.quantity);
      else
        return 0;
    },

    /**
     * Количество товара в корзине неавторизованного пользователя
     */
    getCountInBasketGuestUser() {
      let products = this.$store.state.basketGuest;
      let product = Object.values(products).find(item => item.productId === this.currentProduct.id);

      if (product)
        return parseInt(product.quantity);
      else
        return 0;
    },
  },
};
</script>
