<template>
  <div class="container favorite">
    <div class="favorite-title">
      <div class="title">Избранное</div>
    </div>
    <div class="favorite-list__buttons" v-if="false">
      <button class="btn" @click="openFavoriteListModal = false">
        Новый список
      </button>
      <button
        class="btn"
        :disabled="currentCollectionId === collectionDefault"
        @click="openModal = true"
      >
        Удалить список
      </button>
    </div>
    <div class="favorite-modal" v-if="openFavoriteListModal === true">
      <div class="favorite-input-group">
        <input
          type="text"
          class="input"
          placeholder="Введите название списка"
          autofocus
          v-model="listName"
        />
        <button class="btn" @click="addFavoriteList()">
          Добавить
        </button>
      </div>
    </div>
    <FormSelect
      :label="'Списки'"
      :items="favoriteList"
      v-model="currentCollectionId"
      @change="getFavoriteList()"
    />
    <!-- Modal remove list -->
    <div class="modal" v-if="openModal === true && false">
      <div>Подтверждение удаления!</div>
      <button type="button" @click="removeFavoriteList()">Да</button>
      <button type="button" @click="openModal = false">Нет</button>
    </div>
    <!-- Modal move to favorite list -->
    <div class="modal" v-if="moveToFavoriteListModal === true && false">
      <div>Переместить в список</div>
      <FormSelect
        :label="'Списки'"
        :items="favoriteList"
        v-model="currentMoveToFavoriteList"
        @change="moveToFavoriteList()"
      />
    </div>
    <div class="favorite-cards__wrapper" v-if="favorites.length > 0">
      <div class="favorite-cards">
        <ProductFavorite
          v-for="product in favorites"
          :key="product.id"
          :product="product"
          :classItem="'product-card--big'"
          :selectedProduct="this.selectedProduct"
          @selectedProduct="selectProduct($event)"
        />
      </div>
    </div>
    <div class="free-content" v-else>
      <div class="free-content__icon">
        <img src="/img/icons/basket.svg" />
      </div>
      <div class="free-content__title title">
        Избранных товаров пока не добавлено
      </div>
      <div class="free-content__btn">
        <button class="btn" @click.prevent="$router.push('/catalog')">
          Перейти в каталог
        </button>
      </div>
    </div>
  </div>
  <div class="favorite-menu">
    <button
      type="button"
      class="btn"
      :disabled="selectedProduct.length === 0"
      @click="addBasket()"
    >
      Положить в корзину
    </button>
    <button
      v-if="false"
      type="button"
      class="btn"
      :disabled="selectedProduct.length === 0"
      @click="this.moveToFavoriteListModal = true"
    >
      Переместить в список
    </button>
  </div>
</template>

<script>
import ProductFavorite from "@/components/cards/product-favorite";
import FormSelect from "@/components/forms/form-select";

import store from "@/store/index.js";

export default {
  name: "Favorites",

  beforeRouteEnter(to, from, next) {
    if (store.getters.isAuthenticated)
      store.dispatch("FAVORITES_FETCH_DATA_DEFAULT").then(() => {
        next();
      });
    else next("/login");
  },

  components: {
    ProductFavorite,
    FormSelect
  },

  data() {
    return {
      listName: '',
      selectedProduct: [],
      collectionDefault: null,
      currentCollectionId: null,
      currentMoveToFavoriteList: null,
      openModal: false,
      moveToFavoriteListModal: false,
      openFavoriteListModal: false,
    }
  },

  mounted() {
    this.$nextTick(() => {
      this.favoriteList.forEach((element) => {
        if (element.default)
          this.collectionDefault = element.code;
      });
    })
  },

  computed: {
    favoriteList() {
      return this.$store.state.favorites.favoriteList;
    },

    favorites() {
      return this.$store.state.favorites.favorites;
    },
  },

  methods: {
    getFavoriteList() {
      this.$store
        .dispatch("FAVORITES_FETCH_DATA_COLLECTION", {
          'collectionId': this.currentCollectionId,
        })
        .then(() => {
          this.selectedProduct = [];
        });
    },

    selectProduct($event) {
      if (this.selectedProduct.find(item => item === $event)) {
        let index = this.selectedProduct.findIndex(item => item === $event);
        this.selectedProduct.splice(index, 1);
      } else {
        this.selectedProduct.push($event);
      }
    },

    removeFavoriteList() {
      this.$store
        .dispatch("FAVORITES_REMOVE_LIST", {
          'collectionId': this.currentCollectionId,
        })
        .then(() => {
          window.location.reload();
        });
    },

    moveToFavoriteList() {
      this.$store
        .dispatch("FAVORITES_PRODUCT_MOVE_TO_LIST", {
          'products': this.selectedProduct,
          'collectionId': this.currentMoveToFavoriteList,
          'collectionIdOld': this.currentCollectionId ?? this.collectionDefault
        })
        .then(() => {
          this.getFavoriteList();
        });
    },

    addBasket() {
      this.$store
        .dispatch("FAVORITES_ADD_PRODUCT_TO_BASKET", {
          'products': this.selectedProduct,
        })
        .then(() => {
          this.getFavoriteList();
        });
    },

    addFavoriteList() {
      this.$store
        .dispatch("FAVORITES_ADD_LIST", {
          'listName': this.listName,
        })
        .then(() => {
          this.getFavoriteList();
        });
    },
  },
};
</script>
